const modeEnv = process.env.NODE_ENV || 'development';
let port = ''
export const suportMsg = "Hola, hablo desde Corona Chocolate y necesito ayuda"

// check if mode is in developement or production (Build)
if (modeEnv === 'development') port = ":8000" // :8000
if (modeEnv === 'production') port = '' // para demoeventos se debe colocar el mismo puerto del BACK ':3018'

export const API = window.location.protocol + '//' + window.location.hostname + port
export const ws = window.location.protocol + "//" + window.location.hostname + port
export const uri = process.env.REACT_APP_API_URL
export const adminURL = process.env.REACT_APP_ADMIN_URL

export const FILE = './pages/certificate/certificados.xlsx'
